<template>
  <div class="cont-box">
    <el-row style="margin-bottom: 20px;">
      <el-col :span="24">
        <el-card>
          <search-filter
              :searchName="'搜索题单'"
              :keywordOptions="{placeholder:'搜索题单'}"
              :searchOptions="whereOptions.filter(d=>['diff_id' , 'from_id'].indexOf(d.options.prop) > -1)"
              :otherOptions="whereOptions.filter(d=>['diff_id' , 'from_id'].indexOf(d.options.prop) === -1)"
              v-model="where"
              :total="total"
              @search="$refs.table.reset(...arguments)"
          ></search-filter>
        </el-card>
      </el-col>
      <!-- <el-col :span="6" :offset="1">
        <know-points :height="180" type="questList" />
      </el-col> -->
    </el-row>
    <el-card>
      <com-table
          ref="table"
          :data="{
            url: '/xapi/user.group/questList',
            params:{...$route.query,team_id}
          }"
          :columns="columns"
          :total.sync="total"
          :rowStyle="()=>{return {cursor:'pointer'};}"
          @cell-click="(row)=>{$handleRoute({list_id:row.id},'TeamQuestListInfo')}"
      >
      </com-table>
    </el-card>
  </div>
</template>

<script>
import SearchFilter from "@/components/com/SearchFilter";
import KnowPoints from "@/views/api/com/knowPoints";
export default {
  props: {
    team_id:{
      default: () => 0,
    },
    teamInfo:{
      type:Object,
      default: () => { return {}; },
    },
  },
  components: {KnowPoints, SearchFilter},
  data(){
    return {
      total:0,
      columns:[
        {prop: 'id', label: '编号', width: '80', align: 'center'},
        {prop: 'fromStr',label:'<span><font style="color:red;">来源</font> - <font style="color:green;">创建人</font> - 题单名称</span>',template:(scope)=>{
            return '<font style="color:red;">'+(scope.row.fromStr||'')+'</font> - <font style="color:green;">'+scope.row.user_name+'</font> - '+this.$jquery("<font>"+scope.row.title+"</font>").text();
          }, minWidth: '220', align: 'center'},
        {prop: 'time_str', label: '时间', width: '120', align: 'center'},
        {prop: 'diffStr', label: '难度', width: '120', align: 'center'},
        {prop: 'create_date', label: '创建时间', width: '160', align: 'center'},
      ],
      where: {},
      whereOptions: [
        { name:'el-form-item' ,
          options:{label:'难度' , prop:'diff_id', type:'radio-group' , data:{ name:"question_list", keys:"diff_id" } , defaultValue :'全部' }
        },
        { name:'el-form-item' ,
          options:{label:'来源' , prop:'from_id', type:'radio-group'  , data:{ name:"question_list", keys:"from_id" } , defaultValue :'全部'}
        },
        { name:'el-form-item' ,
          // options:{label:'年份' , prop:'time_str', type:'select' , data:{ name:"question_list", keys:"time_str" , isAll:false } }
           options:{label:'年份' , prop:'time_str', type:'year' , placeholder:'请选择年份'}

        },
        { name:'el-form-item' ,
          options:{label:'地区' , prop:'province_id', type:'select' , data:{ name:"question_list", keys:"province_id" , isAll:false }}
        },
      ],
    }
  },
}
</script>

<style lang="less" scoped>
.cont-box/deep/{
  .know-item{
    margin-left:0;
    .num{
      margin-left: 8px;
      padding: 1px 6px;
      font-size: 12px;
      display: inline-block;
      background: #00957e;
      border-radius: 11px;
      color: #fff;
    }
  }
  .know-item:not(:last-child){
    margin-right:5px;
    margin-bottom: 10px;
  }
}
</style>